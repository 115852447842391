import { Box, Grid } from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Photo } from "@mui/icons-material";
import { GridRowModes } from "@mui/x-data-grid";
import {
  InputButton,
  InputText,
  InputDropDown,
  InputEditTableDropDown,
  InputEditTableFile,
  InputEditTableCurrency,
  EditableDatePicker,
} from "components/UI/index";
import { useCategory, useGetOwner, useGetOwnerByDataOwner, useGetType, useSearchRpackage } from "service/rpackagetype";
import { ModalPrice } from "components/RpackageType/ModalPrice";
import { getLocalDate } from "utils/init-config-date";
import {
  apiClient,
  setDataPriceList,
  parseCurrencyString,
  formatDate,
  findObject,
  responseErrors,
  formatCurrency,
  messageTypeDisplay,
  isValidDate,
  responseDownloadFileErrors,
} from "utils";
import { validationRequestErrors } from "utils/validation";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import InputEditTableText from "components/UI/InputEditTableText";
import ActionBar from "../Partials/ActionBar";
import { MessageType, ModeAction } from "state/enum";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import SubmitBar from "pages/Partials/SubmitBar";
import DataTable from "pages/DataTable";
import ModalPreviewImage from "components/RpackageType/ModalPreviewImage";
import { userProfile } from "constant";
import { validationUploadFile } from "utils/validation";
import _ from "lodash";
import {
  useRPkgTypeMasterCreateMutation,
  useRPkgTypeMasterEditMutation,
  useRPkgTypeMasterDownloadTemplateMutation,
} from "shared/services/master";

import { FIRST_PAGE } from "shared/constants";
const RpackageTypeScreen = () => {
  const confirmDialogCtx = useConfirmDialogContext();

  const [mode, setMode] = useState("view");
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [onSearch, setOnSearch] = useState(false);

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  const [searchForm, setSearchForm] = useState({
    rPkgOwnerCompId: "",
    rPkgTypeId: "",
    rPkgDesc: "",
  });

  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const handleChangePagination = async (event, value) => {
    setMsgError([]);
    setMsgAlert([]);

    setPageNumber(value);

    const { searchData, data } = await getSearch(value);

    setPagination(searchData?.result?.pagination ?? {});
    setPageNumber(searchData?.result?.pagination?.pageNumber);
    setRows(data);
  };

  // 2. api
  const { data: ownerData, isSuccess: isSuccessOwnerData } = useGetOwner();
  const createRPkgType = useRPkgTypeMasterCreateMutation();
  const editRPkgType = useRPkgTypeMasterEditMutation();
  const downloadRPkgType = useRPkgTypeMasterDownloadTemplateMutation();
  const {
    data: typeData,
    isSuccess: isSuccessTypeData,
    refetch: refetchGetType,
  } = useGetType({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompId: searchForm.rPkgOwnerCompId,
  });

  const { mutateAsync: searchDataAsync } = useSearchRpackage({});

  const { data: ownerDataByDataOwner } = useGetOwnerByDataOwner();

  const { data: categoryData } = useCategory();

  // 3. initail value search from
  useEffect(() => {
    if (isSuccessOwnerData) {
      // TODO: R-Package Owner ค่า Default ต้องเป็น Company ที่ Login
      setSearchForm(old => ({
        ...old,
        rPkgOwnerCompId: ownerData?.[0]?.rPkgOwnerCompId,
      }));
      document.getElementById("select_rPkgOwnerCompId").focus();
    }
    return;
  }, [isSuccessOwnerData, ownerData]);

  useEffect(() => {
    if (isSuccessTypeData) {
      setSearchForm(old => ({
        ...old,
        rPkgTypeId: "",
      }));
    }
    return;
  }, [isSuccessTypeData]);

  // 4. columns
  const columns = useMemo(
    () => [
      {
        field: "rowNumber",
        sortable: false,
        headerName: "No",
        align: "right",
        minWidth: 50,
        width: 50,
        editable: false,
        renderHeader: () => <div></div>,
        renderCell: params => {
          if (params?.row?.rowNumber === rows.slice(-1)?.[0]?.rowNumber && ModeAction.ADD === mode) {
            return <div></div>;
          }
          return params.value;
        },
      },
      {
        field: "rPkgOwnerCompId",
        sortable: false,
        headerAlign: "center",
        align: "left",
        headerName: "R-Package Owner",
        minWidth: mode === ModeAction.EDIT || mode === ModeAction.ADD ? 120 : 100,
        editable: true,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
        renderHeader: () => <div></div>,
        renderEditCell: params => {
          if (mode === ModeAction.ADD) {
            return (
              <InputEditTableDropDown
                {...params}
                required
                autoFocus
                memu={ownerData?.map(val => ({
                  key: val.rPkgOwnerCompId,
                  value: val.rPkgOwnerCompAbbr,
                }))}
                placeholder="<Select>"
                defaultValue=""
              />
            );
          } else {
            const name =
              params.value &&
              // ownerData?.find((val) => {
              //   return val.rPkgOwnerCompId == params.value;
              // })?.rPkgOwnerCompAbbr;
              findObject({
                data: ownerData,
                value: params.value,
                property: "rPkgOwnerCompId",
                field: "rPkgOwnerCompAbbr",
              });
            return <p>{name}</p>;
          }
        },
        renderCell: params => {
          const name = findObject({
            data: ownerData,
            value: params.value,
            property: "rPkgOwnerCompId",
            field: "rPkgOwnerCompAbbr",
          });

          return <p>{name}</p>;
        },
        flex: 1,
      },
      {
        field: "categoryCd",
        sortable: false,
        headerAlign: "center",
        align: "left",
        headerName: "R-Package Category",
        type: "select",
        minWidth: mode === ModeAction.EDIT || mode === ModeAction.ADD ? 140 : 100,
        editable: true,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["R-Package Category"],
          },
        ],
        renderHeader: () => <div></div>,
        renderEditCell: params => {
          if (mode === ModeAction.ADD) {
            return (
              <InputEditTableDropDown
                {...params}
                required
                memu={categoryData?.map(val => ({
                  key: val.cd,
                  value: val.value,
                }))}
                placeholder="<Select>"
                defaultValue=""
              />
            );
          } else {
            const name = findObject({
              data: categoryData,
              value: params.value,
              property: "cd",
              field: "value",
            });
            return <p>{name}</p>;
          }
        },
        renderCell: params => {
          const name = findObject({
            data: categoryData,
            value: params.value,
            property: "cd",
            field: "value",
          });
          return <p>{name}</p>;
        },
        flex: 1,
      },
      {
        field: "rPkgType",
        sortable: false,
        headerAlign: "center",
        align: "left",
        headerName: "R-Package Type",
        minWidth: 100,
        editable: true,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["R-Package Type"],
          },
        ],
        renderHeader: () => <div></div>,
        renderEditCell: params => {
          if (mode === ModeAction.ADD) {
            return (
              <InputEditTableText
                {...params}
                required
                maxLength={5}
                // specialCharsRegex change by surasith
                regularExp={/^[a-zA-Z0-9_]*$/}
              />
            );
          } else {
            // return <InputEditTableText {...params} required disabled />;
            return params?.value;
          }
        },
        renderCell: params => {
          return params?.value;
        },
        flex: 1,
      },
      {
        field: "rPkgTypeId",
      },
      {
        field: "rPkgDesc",
        sortable: false,
        headerAlign: "center",
        align: "left",
        headerName: "R-Package Type Description",
        minWidth: 180,
        editable: true,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["R-Package Type Description"],
          },
        ],
        renderHeader: () => <div></div>,
        renderEditCell: params => {
          return <InputEditTableText {...params} required maxLength={30} regularExp={/^.*$/} />;
        },
        renderCell: params => {
          return params?.value;
        },
        flex: 1,
      },
      {
        field: "weight",
        sortable: false,
        headerName: "Weight (Kg)",
        editable: true,
        align: "right",
        headerAlign: "center",
        minWidth: 100,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Weight"],
          },
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Weight", "0"],
          },
        ],
        renderHeader: () => <div></div>,
        renderEditCell: params => <InputEditTableCurrency {...params} required decimalScale={3} maxLimit={10000} />,
        renderCell: params => {
          return formatCurrency({ number: params?.value, digits: 3 });
          // return params?.value;
        },
        flex: 1,
      },
      {
        field: "asbLength",
        sortable: false,
        headerName: "Length",
        type: "string",
        editable: true,
        align: "right",
        headerAlign: "center",
        minWidth: 100,
        width: 100,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Assembly Size(mm) Length"],
          },
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Assembly Size(mm) Length", "0"],
          },
        ],
        renderEditCell: params => {
          return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
        },
        renderCell: params => {
          return params?.value;
        },
        flex: 1,
      },
      {
        field: "asbWidth",
        sortable: false,
        headerName: "Width",
        type: "text",
        editable: true,
        align: "right",
        headerAlign: "center",
        minWidth: 100,
        width: 100,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Assembly Size(mm) Width"],
          },
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Assembly Size(mm) Width", "0"],
          },
        ],
        renderEditCell: params => {
          return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
        },
        renderCell: params => params?.value,
        flex: 1,
      },
      {
        field: "asbHeight",
        sortable: false,
        headerName: "Height",
        type: "string",
        editable: true,
        align: "right",
        headerAlign: "center",
        minWidth: 100,
        width: 100,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Assembly Size(mm) Height"],
          },
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Assembly Size(mm) Height", "0"],
          },
        ],
        renderEditCell: params => {
          return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
        },
        renderCell: params => params?.value,
        flex: 1,
      },
      {
        field: "pic",
        sortable: false,
        headerName: "Picture",
        minWidth: 100,
        width: 100,
        editable: true,
        align: "center",
        headerAlign: "center",
        type: "actions",
        renderEditCell: params => {
          return <InputEditTableFile {...params} uploadProcess={handleUploadPic} mode={mode} />;
        },
        getActions: params => [
          <>
            {params.row.pic && (
              <GridActionsCellItem
                style={{ alignContent: "center" }}
                key={0}
                icon={<Photo />}
                onClick={e => {
                  e.preventDefault();
                  handleModalImage(params.row, "asb");
                }}
              />
            )}
          </>,
        ],
        flex: 1,
      },
      {
        field: "dsbLength",
        sortable: false,
        headerName: "Length",
        type: "string",
        editable: true,
        align: "right",
        headerAlign: "center",
        minWidth: 100,
        width: 100,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Dis-assembly Size(mm) Length"],
          },
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Dis-assembly Size(mm) Length", "0"],
          },
        ],
        renderEditCell: params => {
          return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
        },
        renderCell: params => {
          return params?.value;
        },
        flex: 1,
      },
      {
        field: "dsbWidth",
        sortable: false,
        headerName: "Width",
        type: "string",
        editable: true,
        align: "right",
        headerAlign: "center",
        minWidth: 100,
        width: 100,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Dis-assembly Size(mm) Width"],
          },
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Dis-assembly Size(mm) Width", "0"],
          },
        ],
        renderEditCell: params => {
          return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
        },
        renderCell: params => {
          return params?.value;
        },
        flex: 1,
      },
      {
        field: "dsbHeight",
        sortable: false,
        headerName: "Height",
        type: "string",
        editable: true,
        align: "right",
        headerAlign: "center",
        minWidth: 100,
        width: 100,
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Dis-assembly Size(mm) Height"],
          },
          {
            type: MessageType.MORETHAN_ZERO,
            key: ["Dis-assembly Size(mm) Height", "0"],
          },
        ],
        renderEditCell: params => {
          return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
        },
        renderCell: params => {
          return params?.value;
        },
        flex: 1,
      },
      {
        field: "dsbPic",
        sortable: false,
        headerName: "Picture",
        minWidth: 100,
        width: 100,
        editable: true,
        align: "center",
        headerAlign: "center",
        type: "actions",
        renderEditCell: params => {
          return (
            <>
              <InputEditTableFile {...params} uploadProcess={handleUploadPic} />
            </>
          );
        },
        getActions: params => [
          <>
            {params.row.dsbPic && (
              <GridActionsCellItem
                style={{ alignContent: "center" }}
                key={0}
                icon={<Photo />}
                onClick={e => {
                  e.preventDefault();
                  handleModalImage(params.row, "dsb");
                }}
              />
            )}
          </>,
        ],
        flex: 1,
      },
      {
        field: "price",
        sortable: false,
        minWidth: 120,
        headerName: "Price",
        editable: true,
        align: "center",
        headerAlign: "center",
        renderHeader: () => <div></div>,
        renderEditCell: params => {
          const findRow = rows.find(v => v.rowNumber === params.id).currencys;
          if (findRow.length) {
            return (
              <div
                style={{
                  color: params.row.rowNumber === rowSelectionModel[0] ? "blue" : "black",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (params.row.rowNumber === rowSelectionModel[0]) {
                    handleModalPrice(params.id, params.row.currencys);
                  }
                }}>
                {findRow.map((val, i) => {
                  return <div key={i}>{`${val.price}(${val.currentCd})`}</div>;
                })}
              </div>
            );
          }
          return (
            <InputButton
              value={"Detail"}
              color="info"
              size="small"
              style={{ textTransform: "none", margin: "auto", fontSize: "12px" }}
              onClick={() => {
                if (params.row.rowNumber === rowSelectionModel[0]) {
                  handleModalPrice(params.id);
                }
              }}
              minWidth="80px"
            />
          );
        },
        renderCell: params => {
          const findRow = rows.find(v => v.rowNumber === params.id).currencys;

          if (ModeAction.VIEW === mode) {
            if (findRow.length) {
              return (
                <div
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    handleModalPrice(params.id, params.row.currencys);
                  }}>
                  {findRow.map((val, i) => {
                    return <div key={i}>{`${val.price}(${val.currentCd})`}</div>;
                  })}
                </div>
              );
            }
            return (
              <InputButton
                value={"Detail"}
                color="info"
                size="small"
                style={{ textTransform: "none" }}
                onClick={() => {
                  handleModalPrice(params.id);
                }}
                minWidth="80px"
              />
            );
          } else {
            if (findRow.length) {
              return (
                <div
                  style={{
                    color: params.row.rowNumber === rowSelectionModel[0] ? "blue" : "black",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (params.row.rowNumber === rowSelectionModel[0]) {
                      handleModalPrice(params.id, params.row.currencys);
                    }
                  }}>
                  {findRow.map((val, i) => {
                    return <div key={i}>{`${val.price}(${val.currentCd})`}</div>;
                  })}
                </div>
              );
            }
            return (
              <InputButton
                value={"Detail"}
                color="info"
                size="small"
                style={{ textTransform: "none" }}
                onClick={() => {
                  if (params.row.rowNumber === rowSelectionModel[0]) {
                    handleModalPrice(params.id);
                  }
                }}
                minWidth="80px"
              />
            );
          }
        },
        flex: 1,
      },
      {
        field: "color",
        sortable: false,
        minWidth: mode === ModeAction.EDIT || mode === ModeAction.ADD ? 150 : 120,
        headerName: "Color",
        editable: true,
        align: "left",
        headerAlign: "center",
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Color"],
          },
        ],
        renderHeader: () => <div></div>,
        renderEditCell: params => {
          return <InputEditTableText {...params} required maxLength={20} regularExp={/^[\w\W]*$/} />;
        },
        renderCell: params => {
          return params?.value;
        },
        flex: 1,
      },
      {
        field: "effDtFrom",
        minWidth: mode === ModeAction.EDIT || mode === ModeAction.ADD ? 180 : 120,
        sortable: false,
        headerName: "From",
        editable: true,
        align: "center",
        headerAlign: "center",
        valueGetter: params => {
          if (!params.value) return "";
          return getLocalDate(params.value, "YYYY-MM-DD").format("DD/MM/YYYY");
        },
        renderEditCell: params => {
          if (mode === ModeAction.ADD) {
            return (
              <>
                <EditableDatePicker {...params} required fullWidth />
              </>
            );
          } else {
            return <EditableDatePicker {...params} disabled required value={params.value} fullWidth />;
          }
        },
        flex: 1,
      },
      {
        field: "effDtTo",
        minWidth: mode === ModeAction.EDIT || mode === ModeAction.ADD ? 180 : 120,
        sortable: false,
        headerName: "To",
        editable: true,
        align: "center",
        headerAlign: "center",
        valueGetter: params => {
          if (!params.value) return "";
          return getLocalDate(params.value, "YYYY-MM-DD").format("DD/MM/YYYY");
        },
        renderEditCell: params => {
          return <EditableDatePicker {...params} value={params.value} />;
        },
        flex: 1,
      },
      {
        field: "currencys",
        rule: [
          {
            type: MessageType.LENGTH,
            key: ["Price"],
          },
        ],
      },
    ],
    [rows, rowSelectionModel, mode]
  );

  // 5. columnGroupingModel
  const columnGroupingModel = [
    {
      groupId: "No.",
      headerAlign: "center",
      children: [{ field: "rowNumber" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Owner",
      headerAlign: "center",
      children: [{ field: "rPkgOwnerCompId" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Category",
      headerAlign: "center",
      children: [{ field: "categoryCd" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Type",
      headerAlign: "center",
      children: [{ field: "rPkgType" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "R-Package Type Description",
      headerAlign: "center",
      children: [{ field: "rPkgDesc" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Weight (Kg)",
      headerAlign: "center",
      children: [{ field: "weight" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Assembly Size(mm)",
      headerAlign: "center",
      children: [{ field: "asbLength" }, { field: "asbWidth" }, { field: "asbHeight" }, { field: "pic" }],
      headerClassName: "table-header-group",
    },
    {
      groupId: "Dis-assembly Size(mm)",
      headerAlign: "center",
      children: [{ field: "dsbLength" }, { field: "dsbWidth" }, { field: "dsbHeight" }, { field: "dsbPic" }],
      headerClassName: "table-header-group",
    },
    {
      groupId: "Price",
      headerAlign: "center",
      children: [{ field: "price" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Color",
      headerAlign: "center",
      children: [{ field: "color" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Effective Date (DD/MM/YYYY)",
      headerAlign: "center",
      children: [{ field: "effDtFrom" }, { field: "effDtTo" }],
      headerClassName: "table-header-group",
    },
  ];

  const getSearch = async (pageNumber = 1) => {
    setOnSearch(true);
    const body = {
      rPkgOwnerCompId: searchForm?.rPkgOwnerCompId,
      rPkgType: searchForm?.rPkgTypeId,
      rPkgDesc: searchForm?.rPkgDesc,
      dataOwner: userProfile.dataOwner,
      pageNumber: pageNumber,
      rowsPerPage: 10,
    };
    const searchData = await searchDataAsync(body);

    const data =
      searchData?.result?.data?.map((item, index) => ({
        no: index + 1,
        rowNumber: item?.rowNumber,
        rPkgOwnerCompId: item.companyId,
        categoryCd: item.categoryCd,
        rPkgType: item.rPkgType,
        rPkgTypeId: item.rPkgTypeId,
        rPkgDesc: item.rPkgDesc,
        weight: item.weight,
        asbLength: item.asbLength,
        asbWidth: item.asbWidth,
        asbHeight: item.asbHeight,
        pic: item.asbPic,
        dsbLength: item.dsbLength,
        dsbWidth: item.dsbWidth,
        dsbHeight: item.dsbHeight,
        dsbPic: item.dsbPic,
        // price: (() => setDataPriceList(item.currencys))(),
        price: item.currencys,
        currencys: item.currencys,
        color: item.color,
        effDtFrom: item.effDtFrom,
        effDtTo: item.effDtTo,
        value: item.value,
      })) ?? [];

    return { searchData, data };
  };

  // 6. handleSearch
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      setMode(ModeAction.VIEW);
      setPageNumber(FIRST_PAGE);
      setRows([]);
      setRowSelectionModel([]);
      setRowModesModel({});
      // validate
      let stopProcess = false;

      if (searchForm.rPkgOwnerCompId === "") {
        const msg = messageTypeDisplay(MessageType.EMPTY, ["R-Package Owner"]);
        setMsgError(old => [...old, msg]);
        stopProcess = true;
      }

      const regex1 = /^\*.+$/;
      const regex2 = /^[^*]+\*[^*]+$/;
      const regex3 = /^[^*]+\*[^*]+\*$/;
      const regex4 = /^\*$/;

      if (
        // !regex.test(searchForm.rPkgDesc) && searchForm.rPkgDesc !== ""
        (regex1.test(searchForm.rPkgDesc) ||
          regex2.test(searchForm.rPkgDesc) ||
          regex3.test(searchForm.rPkgDesc) ||
          regex4.test(searchForm.rPkgDesc)) &&
        searchForm.rPkgDesc !== ""
      ) {
        const msg = messageTypeDisplay(MessageType.INVALID, ["R-Package Type Description"]);
        setMsgError(old => [...old, msg]);
        stopProcess = true;
      }

      if (stopProcess) {
        window.scrollTo(0, 0);
        return;
      }

      const { searchData, data } = await getSearch(1);

      if (!data.length) {
        const msg = messageTypeDisplay(MessageType.NOT_FOUND);
        setMsgError(old => [...old, msg]);
        stopProcess = true;
      }

      // const { isSuccess, errors } = validationSearchForm({
      //   data: searchForm,
      //   rule: [
      //     {
      //       field: "rPkgOwnerCompId",
      //       type: MessageType.EMPTY,
      //       key: ["R-Package Owner"],
      //     },
      //     {
      //       data: data,
      //       type: regex.test(searchForm.rPkgDesc) ? MessageType.NOT_FOUND : "",
      //     },
      //   ],
      // });

      // if (!isSuccess) {
      //   setMsgError(errors);
      //   window.scrollTo(0, 0);
      //   return;
      // }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // 7. handleDeleteClick
  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const selectedNo = rowSelectionModel[0];
    const rowsSelected = rows.find(v => v.rowNumber === selectedNo);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });

    if (!confirm) {
      return;
    }

    // TODO: Action Delete
    try {
      await apiClient.delete(`/r-package/type/delete/${rowsSelected?.rPkgTypeId}`, {
        data: { updateBy: userProfile.userId },
      });

      const { searchData, data } = await getSearch(pageNumber);

      if (data.length > 0) {
        setPagination(searchData?.result?.pagination ?? {});
        setPageNumber(searchData?.result?.pagination?.pageNumber);
        setRows(data);
      } else {
        setSearchForm({
          rPkgOwnerCompId: "CMP_1208_000001", // TODO get profile
          rPkgTypeId: "",
          rPkgDesc: "",
        });
        setRows([]);
      }

      setRowSelectionModel([]);
      setMode(ModeAction.VIEW);

      const msg = messageTypeDisplay(MessageType.DELETED);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      refetchGetType();
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const validateEffDtFrom = effDtFromValue => {
    let stopProcess = true;
    const dateFrom = getLocalDate(effDtFromValue, "DD/MM/YYYY");
    const currentDate = getLocalDate();
    if (effDtFromValue === "DD/MM/YYYY" || effDtFromValue === "") {
      const msg = messageTypeDisplay(MessageType.EMPTY, ["Effective  Date From"]);
      setMsgError(oldMsg => [...oldMsg, msg]);
      stopProcess = false;
      return stopProcess;
    }
    if (!isValidDate(effDtFromValue, "DD/MM/YYYY")) {
      const msg = messageTypeDisplay(MessageType.DATE_FORMAT, ["Effective Date From", "DD/MM/YYYY"]);
      setMsgError(oldMsg => [...oldMsg, msg]);
      stopProcess = false;
    }
    // if (!currentDate.isSame(dateFrom)) {
    if (dateFrom.isBefore(currentDate, "day")) {
      const msg = messageTypeDisplay(MessageType.MORETHAN_TODAY, ["Effective Date (From)"]);
      setMsgError(oldMsg => [...oldMsg, msg]);
      stopProcess = false;
    }
    // }
    return stopProcess;
  };
  const validateEffDtTo = (effDtFromValue, effDtToValue) => {
    const dateFrom = getLocalDate(effDtFromValue, "DD/MM/YYYY");
    const dateTo = getLocalDate(effDtToValue, "DD/MM/YYYY");

    const currentDate = getLocalDate();
    // const currentDate = getLocalDate();
    let stopProcess = true;
    if (effDtToValue && effDtToValue !== "DD/MM/YYYY") {
      if (!isValidDate(effDtToValue, "DD/MM/YYYY")) {
        const msg = messageTypeDisplay(MessageType.DATE_FORMAT, ["Effective Date To", "DD/MM/YYYY"]);
        setMsgError(oldMsg => [...oldMsg, msg]);
        stopProcess = false;
      }
      if (dateTo.isBefore(currentDate, "day")) {
        const msg = messageTypeDisplay(MessageType.MORETHAN_TODAY, ["Effective Date (To)"]);
        setMsgError(oldMsg => [...oldMsg, msg]);
        stopProcess = false;
      }
      if (dateTo.diff(dateFrom, "day") < 0) {
        const msg = messageTypeDisplay(MessageType.EQUAL_BETWEEN, ["Effective Date (To)", "Effective Date (From)"]);
        setMsgError(oldMsg => [...oldMsg, msg]);
        stopProcess = false;
      }
      return stopProcess;
    }
    return stopProcess;
  };

  // 8. processRowUpdate (Action Add and Edit)
  const processRowUpdate = async newRow => {
    // validate
    setMsgError([]);
    setMsgAlert([]);
    let stopProcess;
    if (newRow.isNew) {
      stopProcess = await validateEffDtFrom(newRow.effDtFrom);
    }
    stopProcess = await validateEffDtTo(newRow.effDtFrom, newRow.effDtTo);

    const { isSuccess, errors } = validationRequestErrors({
      columns: columns,
      data: newRow,
    });

    if (!isSuccess || !stopProcess) {
      setMsgError(oldMsg => [...oldMsg, ...errors]);
      window.scrollTo(0, 0);
      return;
    }
    // end validate

    if (newRow.isNew) {
      try {
        const body = {
          rPkgOwnerCompId: newRow.rPkgOwnerCompId,
          rPkgOwnerCompArrr: userProfile.packageOwner,
          dataOwner: userProfile.dataOwner,
          categoryCd: newRow.categoryCd,
          rPkgType: newRow.rPkgType,
          rPkgDesc: newRow.rPkgDesc,
          weight: parseCurrencyString(newRow.weight),
          asbLength: parseCurrencyString(newRow.asbLength),
          asbWidth: parseCurrencyString(newRow.asbWidth),
          asbHeight: parseCurrencyString(newRow.asbHeight),
          asbPic: newRow.pic,
          dsbLength: parseCurrencyString(newRow.dsbLength),
          dsbWidth: parseCurrencyString(newRow.dsbWidth),
          dsbHeight: parseCurrencyString(newRow.dsbHeight),
          dsbPic: newRow.dsbPic,
          color: newRow.color,
          effDtFrom: newRow.effDtFrom,
          effDtTo: newRow.effDtTo,
          createBy: userProfile.userId,
          currencys: newRow.currencys?.map(v => {
            return {
              seqNo: v.seqNo,
              // price: parseFloat(v.price.replace(",", "")),
              price: parseCurrencyString(v.price),
              currentCd: v.currentCd,
            };
          }),
        };
        await createRPkgType(body);
        const msg = messageTypeDisplay(MessageType.ADDED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
        refetchGetType();
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    } else {
      // action Update
      try {
        const rPkgTypeId = newRow.rPkgTypeId;
        const body = {
          rPkgOwnerCompId: newRow.rPkgOwnerCompId,
          rPkgOwnerCompArrr: userProfile.packageOwner,
          dataOwner: userProfile.dataOwner,
          // categoryCd: newRow.categoryCd,
          rPkgType: newRow.rPkgType,
          rPkgDesc: newRow.rPkgDesc,
          weight: parseCurrencyString(newRow.weight),
          asbLength: parseCurrencyString(newRow.asbLength),
          asbWidth: parseCurrencyString(newRow.asbWidth),
          asbHeight: parseCurrencyString(newRow.asbHeight),
          asbPic: newRow.pic,
          dsbLength: parseCurrencyString(newRow.dsbLength),
          dsbWidth: parseCurrencyString(newRow.dsbWidth),
          dsbHeight: parseCurrencyString(newRow.dsbHeight),
          dsbPic: newRow.dsbPic,
          color: newRow.color,
          effDtFrom: newRow.effDtFrom,
          effDtTo: newRow.effDtTo,
          updateBy: userProfile.userId,
          currencys: newRow.currencys?.map(v => {
            return {
              seqNo: v.seqNo,
              price: parseFloat(v.price.replace(",", "")),
              currentCd: v.currentCd,
            };
          }),
        };
        await editRPkgType(body, rPkgTypeId);

        const msg = messageTypeDisplay(MessageType.UPDATED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    }

    if (rows.length === 1 && ModeAction.ADD === mode) {
      setOnSearch(false);
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRows([]);
    } else {
      const { searchData, data } = await getSearch();

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    }

    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setOnSearch(false);
    setSearchForm({
      rPkgDesc: "",
      rPkgOwnerCompId: "CMP_1208_000001", // TODO get profile
      rPkgTypeId: "",
    });
    setRows([]);
    setPagination({});
    setPageNumber(1);
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    try {
      // validate
      setMsgError([]);
      setMsgAlert([]);
      try {
        const { data } = await getSearch(1);
        if (!data.length) {
          const msg = messageTypeDisplay(MessageType.NOT_FOUND);
          setMsgError(old => [...old, msg]);
          return;
        }
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      // const filename = `R_Package_Type_Master_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      const body = {
        dataOwner: userProfile.dataOwner,
        rPkgOwnerCompId: searchForm.rPkgOwnerCompId,
        rPkgType: searchForm.rPkgTypeId,
        rPkgDesc: searchForm.rPkgDesc,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      };
      // action download
      await downloadRPkgType(body);
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const handleCopyClick = () => {
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);
    setMode(ModeAction.ADD);

    const rowCopy = rows.find(v => v.rowNumber === rowSelectionModel[0]);
    const maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

    setRows(oldRows => [
      ...oldRows,
      {
        rowNumber: maxNo + 1,
        rPkgOwnerCompId: rowCopy.rPkgOwnerCompId,
        categoryCd: rowCopy.categoryCd,
        rPkgType: rowCopy.rPkgType,
        rPkgTypeId: rowCopy.rPkgTypeId,
        rPkgDesc: rowCopy.rPkgDesc,
        weight: rowCopy.weight,
        asbLength: rowCopy.asbLength,
        asbWidth: rowCopy.asbWidth,
        asbHeight: rowCopy.asbHeight,
        dsbLength: rowCopy.dsbLength,
        dsbWidth: rowCopy.dsbWidth,
        dsbHeight: rowCopy.dsbHeight,
        price: (() => setDataPriceList(rowCopy.price))(),
        currencys: rowCopy.currencys,
        color: rowCopy.color,
        effDtFrom: rowCopy.effDtFrom,
        effDtTo: rowCopy.effDtTo,
        isNew: true,
      },
    ]);

    setRowModesModel(oldModel => ({
      ...oldModel,
      [maxNo + 1]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNo + 1]);
  };

  const onRowSelectionModelChange = newRowSelectionModel => {
    setMsgError([]);
    setMsgAlert([]);

    if (newRowSelectionModel?.length) {
      setMode(ModeAction.SELECTED);
    } else {
      setMode(ModeAction.VIEW);
    }
    setRowSelectionModel(newRowSelectionModel);
  };

  const handleEdit = async () => {
    setMode(ModeAction.EDIT);
    setMsgError([]);
    setMsgAlert([]);

    // validate
    const newRow = rows.find(val => val.rowNumber === rowSelectionModel[0]);
    if (getLocalDate().isAfter(newRow.effDtTo, "days")) {
      const msg = messageTypeDisplay(MessageType.DELETE_EFFECTIVE_DATE_TO_PASS, [
        "delete or edit R-Package Type",
        // "Effective Date To",
        formatDate(newRow.effDtTo, "DD/MM/YYYY"),
      ]);
      setMsgError([msg]);
      window.scrollTo(0, 0);
      return;
    }

    let tempRowModesModel = rowModesModel;

    const rowSelect = rowSelectionModel.map(val => {
      tempRowModesModel = {
        ...tempRowModesModel,
        [val]: { mode: GridRowModes.Edit },
      };
    });

    if (rowSelect) {
      setRowModesModel(tempRowModesModel);
      // setRowSelectionModel()
    }
  };

  // modal price
  const [isOpenModalPrice, setIsOpenModalPrice] = useState(false);
  const [rowNumberPrice, setRowNumberPrice] = useState(null);
  const [rowInitialDataPrice, setRowInitialDataPrice] = useState([]);

  const handleModalPrice = (id, initialData = []) => {
    setIsOpenModalPrice(true);
    setRowNumberPrice(id);
    setRowInitialDataPrice(initialData);
  };

  // modal preview image
  const [isOpenModalImage, setIsOpenModalImage] = useState(false);
  const [rowNumberImage, setRowNumberImage] = useState(null);
  const [modalImageType, setModalImageTypee] = useState(null);

  const handleModalImage = (id, type) => {
    setIsOpenModalImage(true);
    setRowNumberImage(id);
    setModalImageTypee(type);
  };

  const handleUploadPic = async (type, file) => {
    // TODO: Action Upload
    try {
      setMsgError([]);

      // validate
      const { isSuccess, errors } = validationUploadFile({
        file: file,
        rule: [
          {
            type: MessageType.TYPE_IMAGE,
            key: [file.name, "PNG", "JPG", "JPEG"],
          },
          {
            type: MessageType.SIZE_IMAGE,
            key: [file.name, "1.0 MB"],
            maxSize: 1, // mb
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      const _type = type === "dsbPic" ? "dsb" : "asb";

      let res = await apiClient.post(
        `/r-package/upload/${_type}`,
        {
          image: file,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status === 200) {
        alert("upload successfully");
        return res.data.result.path;
      }
      return false;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        {/* Header */}
        <HeaderContentBar title="WDN91010 : R-Package Type Master Maintenance Screen" />
        {/* Alert Message */}
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={0} sx={{ alignItems: "center" }} style={{ marginTop: "10px" }}>
          <Grid item xs={4} style={{ display: "flex" }}>
            <strong style={{margin: "auto 0px", width: "330px", fontSize: "16px"}}>* R-Package Owner : </strong>
            <InputDropDown
              id="select_rPkgOwnerCompId"
              required
              value={searchForm.rPkgOwnerCompId}
              onChange={e => {
                setSearchForm(old => ({
                  ...old,
                  rPkgOwnerCompId: e.target.value,
                }));
              }}
              memu={ownerData?.map(val => ({
                key: val.rPkgOwnerCompId,
                value: val.rPkgOwnerCompAbbr,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<Select>"
              defaultValue=""
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4} sx={{ mt: 2 }} style={{ display: "flex" }}>
            <strong style={{margin: "auto 0px", width: "330px", fontSize: "16px"}}>R-Package Type : </strong>
            <InputDropDown
              id="select_rPkgTypeId"
              value={searchForm.rPkgTypeId}
              onChange={e =>
                setSearchForm(old => ({
                  ...old,
                  rPkgTypeId: e.target.value,
                }))
              }
              memu={typeData?.map(val => ({
                key: val.rPkgType,
                value: val.rPkgType,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<All>"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={6} sx={{ mt: 1, ml: 3 }}  style={{ display: "flex" }}>
            <strong style={{margin: "auto 0px", width: "445px", fontSize: "16px"}}>R-Package Type Description:</strong>
            <InputText
              id="input_rPkgDesc"
              fullWidth={true}
              value={searchForm.rPkgDesc}
              onChange={e => {
                setSearchForm(old => ({
                  ...old,
                  rPkgDesc: e.target.value,
                }));
              }}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              maxLength={30}
              regularExp={/^.*$/}
            />
          </Grid>
        </Grid>
      </Box>
      <ActionBar
        mode={mode}
        rows={rows}
        columns={columns}
        setRows={setRows}
        setMode={setMode}
        setMsgError={setMsgError}
        setMsgAlert={setMsgAlert}
        onSearch={onSearch}
        setOnSearch={setOnSearch}
        setRowModesModel={setRowModesModel}
        setRowSelectionModel={setRowSelectionModel}
        rowSelectionModel={rowSelectionModel}
        rowModesModel={rowModesModel}
        handleSearch={handleSearch}
        handleClear={handleClear}
        handleDownloadExcel={handleDownloadExcel}
        handleDeleteClick={handleDeleteClick}
        handleCopyClick={handleCopyClick}
        handleEdit={handleEdit}
        firstField={"select_rPkgOwnerCompId"}
        functionId={"WDN91010"}
      />
      <Box sx={{ padding: "1rem" }}>
        <Grid>
          {onSearch && rows.length !== 0 && (
            <>
              <DataTable
                mode={mode}
                onSearch={onSearch}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                setMode={setMode}
                rows={rows}
                rowModesModel={rowModesModel}
                columns={columns}
                setRowModesModel={setRowModesModel}
                processRowUpdate={processRowUpdate}
                pagination={pagination}
                pageNumber={pageNumber}
                handleChangePagination={handleChangePagination}
                columnGroupingModel={columnGroupingModel}
                columnVisibilityModel={{
                  currencys: false,
                  rPkgTypeId: false,
                }}
                onRowSelectionModelChange={onRowSelectionModelChange}
              />
            </>
          )}
          <SubmitBar
            mode={mode}
            rows={rows}
            setMode={setMode}
            setRows={setRows}
            setRowModesModel={setRowModesModel}
            setMsgError={setMsgError}
            rowModesModel={rowModesModel}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            functionId={"WDN91010"}
          />
        </Grid>
      </Box>

      {/* ModalPrice */}
      <ModalPrice
        open={isOpenModalPrice}
        setOpen={setIsOpenModalPrice}
        rowNumber={rowNumberPrice}
        initialData={rowInitialDataPrice}
        setRowsCurrencys={setRows}
        rowsCurrencys={rows}
        modeRows={mode === "selected" ? "view" : mode}
        functionId={"WDN91012"}
      />

      {/* ModalPreviewImage */}
      <ModalPreviewImage
        open={isOpenModalImage}
        setOpen={setIsOpenModalImage}
        row={rowNumberImage}
        // initialData={rows}
        type={modalImageType}
        functionId={"WDN91011"}
      />
    </Fragment>
  );
};

export default RpackageTypeScreen;
